import React, {useState} from "react"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import '../css/main.css'
import PropTypes from "prop-types"
import NavMobile from './NavMobile/NavMobile'

const Layout = (props) => { 
  const [english, setEnglish] = useState(true)
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => setShowMenu(!showMenu)

  
  function changeLanguage(event) {
    if(event.target.name === "dutch"){
      setEnglish(false)
    } else {
      setEnglish(true)
    } 
  }

  return (
    <>
      {showMenu && <NavMobile english={english} changeLanguage={changeLanguage} />}
      <Header 
        changeLanguage={changeLanguage} 
        english={english}
        toggleMenu={toggleMenu}
        showMenu={showMenu}/>
      <div className="wrapper">
        {props.children}
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


