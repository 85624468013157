import React from 'react'
import {Link} from 'gatsby'
import './nav-mobile.css'

function NavMobile({english, changeLanguage}) {

    let content = {
        dutch: {
          about: "Over mij",
          lessons: "Hoboles",
          ensembles: "Ensembles",
          media: "Media",
          contact: "Contact"
        },
        english: {
          about: "About me",
          lessons: "Oboe Lessons",
          ensembles: "Ensembles",
          media: "Media",
          contact: "Contact"
        }
      }
      
      english 
        ? (content = content.english)
        : (content = content.dutch);

  return (
    <nav className="navbar-small">
        <ul className="nav-list">
            <Link to="/about/" activeClassName="active" className="list-item"><li>{content.about}</li></Link>
            <Link to="/lessons/" activeClassName="active" className="list-item"><li>{content.lessons}</li></Link>
            <Link to="/ensembles/" activeClassName="active" className="list-item"><li>{content.ensembles}</li></Link>
            <Link to="/media/" activeClassName="active" className="list-item"><li>{content.media}</li></Link>
            <Link to="/contact/" activeClassName="active" className="list-item"><li>{content.contact}</li></Link>
        </ul> 
        {/* <div className="languages">
        <StaticImage 
            src="../../images/icons/dutch-flag.png" 
            alt="dutch flag"
            width={40}
            layout="fixed"
            name="dutch"
            onClick={changeLanguage}>
        </StaticImage>
        <StaticImage 
            src="../../images/icons/english-flag.png" 
            alt="english flag"
            width={40}
            layout="fixed"
            name="enlgish"
            onClick={changeLanguage}>
        </StaticImage>
        </div> */}
    </nav>
  )
}

export default NavMobile