import React from 'react'
import './header.css'
import { Link } from 'gatsby'
import { Sling as Hamburger } from 'hamburger-react' 
import Nav from '../Nav'
import './header.css'
import '../../css/main.css'

function Header({english, changeLanguage, toggleMenu, showMenu}) {
  // console.log(showMenu)

  return (
    <header className="header">
      <div className="logo">
        <Link to="../../" >
          <h1 className="header-title">Quentin Rychner</h1>
          <h2 className="logo-subtitle">Oboist, teacher & composer</h2>
        </Link>
       
    </div>
    <div className="hamburger-icon">
          <Hamburger size={30} onToggle={toggleMenu}/>
        </div>
     
    <Nav english={english} changeLanguage={changeLanguage}/>
      
    </header>
  )
}

export default Header;
