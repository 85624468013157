import React from 'react'
import './footer.css'

function Footer() {
  return (
    <footer className="footer">
      © {new Date().getFullYear()}, Built by Robin de Zeeuw
    </footer>
    );
}

export default Footer;
