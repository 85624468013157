
 import * as React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 
 function Seo({ description, lang, meta, title }) {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
           }
         }
       }
     `
   )

   const metaDescription = description || site.siteMetadata.description
   const defaultTitle = site.siteMetadata?.title
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       title={title}
       titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
       meta={[
         {
           name: `description`,
           content: metaDescription,
         },
         { 
          name: `google-site-verification`, 
          content: `h8PqCaq0TMkjji0gm2KbWcGqxpUlcLfnkIQl2L9TDxA`
        },
         {
           property: `og:type`,
           content: `website`,
         },
       ].concat(meta)}
     />
   )
 }
 
 Seo.defaultProps = {
   lang: `en`,
   meta: [],
   description: `Quentin Rychner is a multifaceted musician: oboist, teacher, and composer. He plays in symphonic orchestras and teaches privately in The Hague. Quentin Rychner is een veelzijdige musicus: hoboïst, docent en componist. Hij speelt in symfonieorkesten en geeft les in Den Haag.`,
 }
 
 Seo.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
 }
 
 export default Seo